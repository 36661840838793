module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-X7JXJCTL83","cookieName":"gatsby-gdpr","anonymize":true,"allowAdFeatures":false},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://thibaultcrouzet.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["fr-fr","en-gb"],"defaultLanguage":"fr-fr","siteUrl":"https://thibaultcrouzet.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"lowerCaseLng":true},"pages":[{"matchPath":"/:lang?/articles/","getLanguageFromPath":true},{"matchPath":"/:lang?/articles/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/articles/categories/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/articles/categories/:uid/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/articles/tags/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/articles/tags/:uid/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/article/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/services/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/services/:uid/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/categories/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/categories/:uid/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/tags/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/tags/:uid/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/technologies/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/technologies/:uid/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/languages/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisations/languages/:uid/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/realisation/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Thibault Crouzet","short_name":"Thibault Crouzet","description":"Développeur Web à Roanne spécialisé en création, refonte, développement, maintenance et référencement de sites Web.","lang":"fr","display":"standalone","start_url":"/","background_color":"#ffffff","theme_color":"#377dff","icon":"src/images/logos/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0e58c0cb45bd8eb9e8e55cd3b7e6a6b7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/thibaultcrouzet/thibaultcrouzet/node_modules/gatsby-remark-prismjs","id":"96900835-4b61-53a8-8a41-f46b0b717fb0","name":"gatsby-remark-prismjs","version":"7.11.0","modulePath":"/home/runner/work/thibaultcrouzet/thibaultcrouzet/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/thibaultcrouzet/thibaultcrouzet","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
